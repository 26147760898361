(function ($) {

    JF.initialize({apiKey: "c339499b92a22a8c264481d93eee102a"});

    /* =================================================
     Mobile Menu
     ==================================================*/
    var toggleMobileMenu = function () {
        $(".nav").toggleClass("open");
    };

    var closeMobileMenu = function () {
        $(".nav").removeClass("open");
    };

    //Check if mobile menu is needed on window load and resize
    $(".nav-toggle").click(toggleMobileMenu);
    $("#blackLayer").click(closeMobileMenu);
    $(".nav .logo").click(closeMobileMenu);

    //When page scrolled
    function scrollActions() {
        var y_scroll_pos = window.pageYOffset;
        var menushowpoint = 120;             // set to whatever you want it to be
        var barsshowpoint = 25;             // set to whatever you want it to be

        if (y_scroll_pos > menushowpoint) {
            $(".nav--scroll").addClass("show");
        } else {
            $(".nav--scroll").removeClass("show");
        }

        if (y_scroll_pos > barsshowpoint) {
            $(".nav").addClass("show-borders");
        } else {
            $(".nav").removeClass("show-borders");
        }
    }

    scrollActions();

    $(window).scroll(scrollActions);

    // From https://davidwalsh.name/javascript-debounce-function.
    var debounce = function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function later() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    //Image Background Exchanger
    function set_cover_image(target) {
        var ww = $(window).width(),
            wh = $(window).height();

        var tabletBreakpoint = 1200;
        var mobileBreakpoint = 767;

        $(target).each(function (index) {
            var imgsrc = $(this).attr('data-desktopimg');

            if (ww < tabletBreakpoint && ww > mobileBreakpoint) {


                var tabletimgp = $(this).attr('data-tabletimgp');
                if (tabletimgp) {
                    imgsrc = tabletimgp;
                }

                if (ww > wh || !tabletimgp) {
                    var tabletimg = $(this).attr('data-tabletimg');
                    if (tabletimg) {
                        imgsrc = tabletimg;
                    }
                }
            }
            else if (ww < mobileBreakpoint) {

                var mobileimgp = $(this).attr('data-mobileimgp');

                if (mobileimgp) {
                    imgsrc = mobileimgp;
                }

                var mobileimg = $(this).attr('data-mobileimg');

                if (ww > wh || !mobileimgp) {
                    if (mobileimg !== "") {
                        imgsrc = mobileimg;
                    }
                }


            }

            if (imgsrc) {
                if ($(this).css('background-image') !== imgsrc) {
                    $(this).css('background-image', 'url("' + imgsrc + '")');
                }
            }
        });
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                $(window).resize(function () {
                    set_cover_image('.bkg_container');

                    if (window.rellax) {
                        window.rellax.refresh();
                    }
                });

                //Setup calls to change image backgrounds for smaller devices
                set_cover_image('.bkg_container');

                function addBlacklistClass() {
                    $('a').each(function () {
                        if (this.href.indexOf('/wp-admin/') !== -1 ||
                            this.href.indexOf('/wp-login.php') !== -1) {
                            $(this).addClass('wp-link');
                        }
                    });
                }

                addBlacklistClass();

                //Removed cached attr
                $("*[data-sr-id]").removeAttr("data-sr-id");

                /***==============================================
                 * Element Reveals
                 ===============================================*/
                AOS.init({
                    duration: 1200,
                    once: true
                });
                AOS.refresh();

                if (window.matchMedia("(min-width: 770px)").matches) {
                    if ($('.js-rellax').length > 0) {
                        window.rellax = new Rellax('.js-rellax');
                    }
                }
            },
            finalize: function () {
                /***==============================================
                 * Expanding Section Scripts
                 ===============================================*/
                $(".expanding-section__item__header").click(function () {
                    var $elementParent = $(this).closest(".expanding-section__item");
                    $elementParent.toggleClass("is-open");
                    $elementParent.find(".expanding-section__item__body").slideToggle(function () {
                        if (window.rellax) {
                            window.rellax.refresh();
                        }
                    });
                });


                /***==============================================
                 * Contact Forms
                 ===============================================*/
                    // I only have one form on the page but you can be more specific if need be.
                var $form = $('#mc-embedded-subscribe-form');

                if ($form.length > 0) {
                    $form.parsley().on('field:validated', function () {
                    })
                        .on('form:submit', function () {
                            register($form);

                            return false;
                        });
                }

                function register($form) {
                    $.ajax({
                        type: $form.attr('method'),
                        url: $form.attr('action'),
                        data: $form.serialize(),
                        cache: false,
                        dataType: 'json',
                        contentType: "application/json; charset=utf-8",
                        error: function (err) {
                            alert("Could not connect to the registration server. Please try again later.");
                        },
                        success: function (data) {
                            if (data.result !== "success") {
                                // Something went wrong, do something to notify the user. maybe alert(data.msg);
                                console.log(data.msg);
                                $(".message-box").show();
                                $(".message-box").html(data.msg);
                            } else {
                                // It worked, carry on...
                                console.log("SUCCESS")
                                $(".message-box").show();
                                $(".message-box").html("You're now subscribed!");

                                $form.find("input").attr("disabled", "disabled")
                            }
                        }
                    });
                }

            }
        },
        'home': {
            init: function () {
                $('.service-item').hover(function () {
                    var id = $(this).attr("data-id");
                    $(".service-content[data-id=" + id + "]").addClass('is-hover');
                }, function () {
                    var id = $(this).attr("data-id");
                    $(".service-content[data-id=" + id + "]").removeClass('is-hover');
                });

                $('.service-item').click(function () {
                    var id = $(this).attr("data-id");
                    $(".home__services").addClass('is-active');
                    $(".service-content[data-id=" + id + "]").addClass('is-active');
                    $(".home__services--overview").addClass('is-hidden');
                });

                $(".close-icon").click(function () {
                    $(".home__services").removeClass('is-active');
                    $(".service-content").removeClass('is-active');
                    $(".home__services--overview").removeClass('is-hidden');
                });


                var canvas = document.querySelector('#scene');
                var width = canvas.offsetWidth,
                    height = canvas.offsetHeight;

                var renderer = new THREE.WebGLRenderer({
                    canvas: canvas,
                    antialias: true
                });
                renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1);
                renderer.setSize(width, height);
                renderer.setClearColor(0x331746);

                var scene = new THREE.Scene();

                var camera = new THREE.PerspectiveCamera(100, width / height, 1, 1000);
                camera.position.set(0, 0, 190);

                var light = new THREE.HemisphereLight(0xffffff, 0x0C056D, 0.6);
                scene.add(light);

                var light = new THREE.DirectionalLight(0xb8338b, 0.5);
                light.position.set(200, 300, 400);
                scene.add(light);

                var light2 = light.clone();
                light2.position.set(-200, 300, 400);
                scene.add(light2);

                var geometry = new THREE.IcosahedronGeometry(120, 4);
                for(var i = 0; i < geometry.vertices.length; i++) {
                    var vector = geometry.vertices[i];
                    vector._o = vector.clone();
                }

                var material = new THREE.MeshPhongMaterial({
                    emissive: 0xb8338b,
                    emissiveIntensity: 0.4,
                    shininess: 0
                });

                var shape = new THREE.Mesh(geometry, material);
                scene.add(shape);

                function updateVertices (a) {
                    for(var i = 0; i < geometry.vertices.length; i++) {
                        var vector = geometry.vertices[i];
                        vector.copy(vector._o);
                        var perlin = noise.simplex3(
                            (vector.x * 0.006) + (a * 0.0002),
                            (vector.y * 0.006) + (a * 0.0003),
                            (vector.z * 0.006)
                        );
                        var ratio = ((perlin*0.4 * (mouse.y+0.1)) + 0.8);
                        vector.multiplyScalar(ratio);
                    }
                    geometry.verticesNeedUpdate = true;
                }

                function render(a) {
                    requestAnimationFrame(render);
                    updateVertices(a);
                    renderer.render(scene, camera);
                }

                function onResize() {
                    canvas.style.width = '';
                    canvas.style.height = '';
                    width = canvas.offsetWidth;
                    height = canvas.offsetHeight;
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();
                    renderer.setSize(width, height);
                }

                var mouse = new THREE.Vector2(0.8, 0.5);
                function onMouseMove(e) {
                    TweenMax.to(mouse, 0.8, {
                        y: (e.clientY / height),
                        x : (e.clientX / width),
                        ease: Power1.easeOut
                    });
                }

                requestAnimationFrame(render);
                $(".home__header")[0].addEventListener("mousemove", onMouseMove);
                var resizeTm;
                window.addEventListener("resize", function(){
                    resizeTm = clearTimeout(resizeTm);
                    resizeTm = setTimeout(onResize, 200);
                });
            }
        },
        'about': {
            init: function () {


            }
        },
        'case_studies': {
            init: function () {

                var mixerConfig = {
                    multifilter: {
                        enable: true, // enable the multifilter extension for the mixer,
                        logicWithinGroup: 'and',
                        logicBetweenGroups: 'and'
                    },
                    selectors: {
                        target: '.case-studies__case'
                    }
                };

                window.mixer = mixitup('.case-studies__cases', mixerConfig);

            }
        },
        'contact': {
            init: function () {

                ////////////////////////////
                // Load All Map Scripts
                ////////////////////////////

                // Basic options for a simple Google Map
                // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
                var mapOptions = {
                    // How zoomed in you want the map to start at (always required)
                    zoom: 17,

                    disableDefaultUI: true,

                    // The latitude and longitude to center the map (always required)
                    center: new google.maps.LatLng(mapConfig.lng, mapConfig.lat), // New York

                    // How you would like to style the map.
                    // This is where you would paste any style found on Snazzy Maps.
                    styles: [
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.province",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                },
                                {
                                    "saturation": "-100"
                                },
                                {
                                    "lightness": "30"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                },
                                {
                                    "gamma": "0.00"
                                },
                                {
                                    "lightness": "74"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#34334f"
                                },
                                {
                                    "lightness": "-37"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "lightness": "3"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2d2c45"
                                },
                                {
                                    "lightness": "0"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#000000"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#7d7c9b"
                                },
                                {
                                    "lightness": "43"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#2d2c45"
                                },
                                {
                                    "lightness": "1"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#7d7c9b"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#2d2c45"
                                },
                                {
                                    "lightness": "-1"
                                },
                                {
                                    "gamma": "1"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "hue": "#ff0000"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#7d7c9b"
                                },
                                {
                                    "lightness": "-31"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#2d2c45"
                                },
                                {
                                    "lightness": "-36"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#2d2c45"
                                },
                                {
                                    "lightness": "0"
                                },
                                {
                                    "gamma": "1"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
                };

                // Get the HTML DOM element that will contain your map
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('map');

                // Create the Google Map using our element and options defined above
                var map = new google.maps.Map(mapElement, mapOptions);

                // Let's also add a marker while we're at it
                var image = mapConfig.image;

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(mapConfig.lng, mapConfig.lat),
                    map: map,
                    title: 'Belgrave House',
                    icon: image
                });

                /***==============================================
                 * Contact Forms
                 ===============================================*/
                    // I only have one form on the page but you can be more specific if need be.
                var $form = $('#82526081512350');

                if ($form.length > 0) {
                    $form.parsley().on('field:validated', function () {
                    })
                        .on('form:submit', function () {
                            register($form);

                            return false;
                        });
                }

                function register($form) {
                    function successSubmission(data) {
                        $(".message-box").show();
                        $(".message-box").html("Form Submitted!");
                        $form.find(".form__input").attr("disabled", "disabled")
                    }

                    function errorSubmission(data) {
                        console.log(data.msg);
                        $(".message-box").show();
                        $(".message-box").html(data.msg);
                    }

                   var formObj = [];

                    $(".form__input").each(function () {
                       var elementID = $(this).parent().attr("id").replace("cid_", "");
                       var elementValue = $(this).val();
                       formObj[elementID] = elementValue;
                    });

                    JF.createFormSubmission('82526081512350', formObj, successSubmission, errorSubmission);
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            var pageName = $(".main").children().attr("id");

            $.each(pageName.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };


    // Load Events
    function hideLoadingScreen($container) {
        var loading = imagesLoaded($container, {background: true}, function () {
            // images have loaded
            $container.removeClass('loading');

            $(".loading-screen").removeClass('animate');
            $(".loading-screen").addClass('animate-end');

        });
    }

    /* =================================================
     Page Specific JS
     ==================================================*/

    UTIL.loadEvents();

    /* =================================================
     Smooth State
     ==================================================*/
    var settings = {
        debug: false,
        anchors: 'a',
        blacklist: '.wp-link',
        cacheLength: 0,
        loadingClass: 'loading',
        onStart: {
            render: function ($container) {

                $(".loading-screen").removeClass('animate-end');

                $(".loading-screen").addClass('animate');


                $("html").animate({scrollTop: 0}, "slow");


                closeMobileMenu();
            }
        },
        onProgress: {
            duration: 750, // ms

            // How long this animation takes
            render: function ($container) {
            }
        },
        onAfter: function ($container) {

            UTIL.loadEvents();

            hideLoadingScreen($container);

            var str = location.href.toLowerCase();

            $('#menu-menu li a').each(function () {

                if (str.indexOf(this.href.toLowerCase()) > -1) {
                    $('#menu-menu li.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
            });
        }
    };

    var smState = $('#main').smoothState(settings);
    var content = smState.data('smoothState');


    $('[data-sm-link], .menu-nav a').on('click', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        var currentUrl = window.location.href.replace(window.location.protocol, "").replace("#", "");

        if (href !== currentUrl && href !== window.location.href) {
            content.load(href);
        } else {
            $("html").animate({scrollTop: 0}, "slow");
        }
    });


})(jQuery); // Fully reference jQuery after this point.



